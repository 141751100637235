import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import Spinner from "../Spinner/Spinner";
import AssignDriverModal from "./AssignDriverModal";
import DriverSuggestionsSidebar from "./DriverSuggestionsSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  faUserPlus,
  faLightbulb,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const ParticipantsList = ({
  eventId,
  event,
  refreshData,
  eventParticipations,
  isEventExpired,
  suggestions,
}) => {
  const [participants, setParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [participantToAssign, setParticipantToAssign] = useState(null);

  const [isAssignDriverModalOpen, setIsAssignDriverModalOpen] = useState(false);
  const [showDriverSuggestions, setShowDriverSuggestions] = useState(false);
  const [driversWithCapacity, setDriversWithCapacity] = useState([]);

  const { updateItem: updateEventParticipation } = useCRUD(
    "/api/event-participations"
  );

  // Function to handle driver assignment
  const handleAssignDriver = async (driverId, participantIds) => {
    // Ensure participantIds is an array and driverId is a single ID
    const updatePromises = participantIds.map((participantId) =>
      updateEventParticipation(participantId, { assignedDriverId: driverId })
    );

    try {
      await Promise.all(updatePromises);
      console.log("All participants updated successfully.");
      refreshData(); // Refresh data to reflect the changes
    } catch (error) {
      console.error("Error assigning driver:", error);
    } finally {
      setIsAssignDriverModalOpen(false);
      setSelectedParticipants([]);
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: "needRide",
    direction: "descending",
  });

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleCloseAssignDriverModal = () => {
    setSelectedParticipants([]);
    setSelectedParticipants([]);
    setIsAssignDriverModalOpen(false);
  };

  const availableDrivers = eventParticipations.filter(
    (participant) => participant.isDriver && participant.availability !== "No"
  );

  // Open AssignDriverModal with selected participants
  const openAssignDriverModalForSelected = () => {
    setParticipantToAssign(selectedParticipants); // Assuming selectedParticipants is an array of selected participant IDs

    setIsAssignDriverModalOpen(true);
  };

  const isRSVPOnly = event?.eventSignUpType === "RSVP Only";

  useEffect(() => {
    if (eventParticipations) {
      let sortedParticipants = [...eventParticipations];
      if (sortConfig.key !== null) {
        sortedParticipants.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      const filteredParticipants = sortedParticipants.filter(
        (participant) => !participant.isDriver
      );
      setParticipants(filteredParticipants);
    }
  }, [eventParticipations, sortConfig]);

  const handleCheckboxChange = (participantId, isChecked) => {
    setSelectedParticipants((prevSelected) =>
      isChecked
        ? [...prevSelected, participantId]
        : prevSelected.filter((id) => id !== participantId)
    );
  };

  useEffect(() => {
    const driversWithAvailableCapacity = availableDrivers.map((driver) => {
      const assignedLoad = participants.reduce((total, participant) => {
        return participant.assignedDriverId &&
          participant.assignedDriverId.id === driver.participantId
          ? total + (participant.noOfPpl || 1) // Assume 1 if noOfPpl is not set
          : total;
      }, 0);

      return {
        ...driver,
        availableCapacity: Math.max(0, driver.canTake - assignedLoad),
      };
    });

    setDriversWithCapacity(driversWithAvailableCapacity);

    console.log(
      "Drivers with available capacity:",
      driversWithAvailableCapacity
    );
  }, [eventParticipations]);

  const canAssignDriver = (driverId, participantIds, totalPeople) => {
    const driver = driversWithCapacity.find(
      (driver) => driver.participantId === driverId
    );
    if (!driver) {
      console.error("Driver not found");
      return false;
    }

    const assignedLoad = participants.reduce((total, participant) => {
      return participant.assignedDriverId &&
        participant.assignedDriverId.id === driverId
        ? total + (participant.noOfPpl || 1)
        : total;
    }, 0);
    console.log("Assigned count for", driver.name, ":", assignedLoad);
    console.log("Total people:", totalPeople);
    console.log("Driver can take:", driver.canTake);

    return assignedLoad + totalPeople <= driver.canTake;
  };

  const getSuggestionForParticipant = (participantId) => {
    const suggestion = (suggestions || []).find(
      (suggestion) => suggestion.event_participation_id === participantId
    );

    if (!suggestion) return null;

    console.log("suggestion", suggestion);

    const driver = driversWithCapacity.find(
      (driver) => driver.participantId === suggestion.driver_id
    );

    console.log("driver", driver);
    if (!driver || driver.availableCapacity <= 0) {
      return null;
    }

    return suggestion;
  };

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      {isLoading && <Spinner />}

      {isAssignDriverModalOpen && (
        <AssignDriverModal
          isOpen={isAssignDriverModalOpen}
          onClose={handleCloseAssignDriverModal}
          participants={participantToAssign}
          allParticipants={participants}
          availableDrivers={availableDrivers} // You need to define this list based on your conditions
          onAssign={handleAssignDriver}
        />
      )}

      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">
            {event?.pingSchedule
              ? "Member Responses"
              : `${participants?.length} Event Members`}
          </h3>

          <div
            className="tooltip"
            data-tip="Click checkbox of participants you want to assign"
          >
            {!isRSVPOnly && (
              <button
                onClick={openAssignDriverModalForSelected}
                disabled={selectedParticipants.length === 0 || isEventExpired}
                className="btn bg-[#004a7e] text-white mt-4 hover:bg-[#004a7e] hover:text-white btn-sm"
              >
                Assign Driver
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div
            className="tooltip"
            data-tip="Click checkbox of participants you want to assign"
          ></div>
        </div>

        {participants?.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table w-full hidden md:table">
              <thead>
                <tr>
                  {!isRSVPOnly && <th></th>} {/* Hide checkbox for RSVP-only */}
                  <th onClick={() => requestSort("name")}>
                    Member{" "}
                    {sortConfig.key === "name" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  {!isRSVPOnly && (
                    <th onClick={() => requestSort("rideLocation")}>
                      Pickup Location{" "}
                      {sortConfig.key === "rideLocation" &&
                        (sortConfig.direction === "ascending" ? "▲" : "▼")}
                    </th>
                  )}
                  <th onClick={() => requestSort("needRide")}>
                    {isRSVPOnly ? "RSVP" : "Need Ride"}{" "}
                    {sortConfig.key === "needRide" &&
                      (sortConfig.direction === "ascending" ? "▲" : "▼")}
                  </th>
                  <th> Message To Admin</th>
                  {!isRSVPOnly && (
                    <th onClick={() => requestSort("assignedDriverId?.name")}>
                      Driver{" "}
                      {sortConfig.key === "assignedDriverId?.name" &&
                        (sortConfig.direction === "ascending" ? "▲" : "▼")}
                    </th>
                  )}
                </tr>
              </thead>

              <tbody>
                {participants.map((participant) => {
                  const suggestion = getSuggestionForParticipant(
                    participant.id
                  );

                  return (
                    <tr key={participant.id}>
                      {!isRSVPOnly && (
                        <th>
                          <input
                            type="checkbox"
                            disabled={participant.needRide === "No"}
                            className="checkbox checkbox-sm"
                            onChange={(e) =>
                              handleCheckboxChange(
                                participant,
                                e.target.checked
                              )
                            }
                          />
                        </th>
                      )}
                      <td>
                        <div className="flex items-center gap-3">
                          <div>
                            <div className="font-bold">
                              <a
                                href={`/p/${participant.uniqueCode}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {participant.name}
                                {participant.noOfPpl > 1 && (
                                  <span className="ml-2 bg-[#004a7e] text-white py-1 px-2  text-xs">
                                    {participant.noOfPpl}{" "}
                                    <FontAwesomeIcon icon={faUsers} />
                                  </span>
                                )}
                              </a>
                            </div>

                            <div className="text-sm opacity-50">
                              {participant.phone}
                            </div>
                          </div>
                        </div>
                      </td>
                      {!isRSVPOnly && <td>{participant.rideLocation}</td>}
                      <td>
                        <span
                          className={`badge ${
                            participant.needRide === "Yes"
                              ? "badge-success text-white"
                              : participant.needRide === "No"
                              ? "badge-error text-white"
                              : "badge-warning text-white"
                          } badge-lg`}
                        >
                          {participant.needRide}
                        </span>
                      </td>
                      <td>{participant.messageForAdmin}</td>

                      {!isRSVPOnly && (
                        <td>
                          {participant.assignedDriverId ? (
                            <div className="badge bg-[#ff9100] text-white">
                              <span>
                                <i className="fas fa-car-side"></i> &nbsp;
                                {participant.assignedDriverId.name}
                              </span>{" "}
                            </div>
                          ) : (
                            <div>
                              {participant.needRide === "Yes" ? (
                                suggestion ? (
                                  <div
                                    className="tooltip tooltip-top"
                                    data-tip={`Paired ${suggestion.times_paired} times. Click to assign.`}
                                  >
                                    <button
                                      className="badge bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded cursor-pointer flex items-center gap-2"
                                      onClick={() => {
                                        if (
                                          canAssignDriver(
                                            suggestion.driver_id,
                                            participant.id,
                                            participant.noOfPpl
                                          )
                                        ) {
                                          handleAssignDriver(
                                            suggestion.driver_id,
                                            [participant.id]
                                          );
                                        } else {
                                          alert(
                                            "Driver will be beyond capacity if this assignment happens."
                                          );
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faLightbulb} />
                                      <span>
                                        Suggestion: {suggestion.driver}
                                      </span>
                                    </button>
                                  </div>
                                ) : (
                                  <span className="badge badge-error text-white">
                                    No Driver
                                  </span>
                                )
                              ) : (
                                <span className="badge badge-error text-white">
                                  No Ride Needed
                                </span>
                              )}
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="md:hidden">
              {participants.map((participant) => (
                <div
                  key={participant.id}
                  className="p-4 border-b flex items-center relative"
                >
                  {participant.needRide === "Yes" && (
                    <div className="ribbon-badge">
                      <span>
                        {participant.assignedDriverId
                          ? "Assigned"
                          : isRSVPOnly
                          ? "Coming" // Change label for RSVP-only events
                          : "Need Ride"}
                      </span>
                    </div>
                  )}

                  {participant.needRide === "No" && (
                    <div className="ribbon-badge-no">
                      <span>Not Coming</span>
                    </div>
                  )}

                  {participant.needRide === "Pending" && (
                    <div className="ribbon-badge-pending">
                      <span>Pending</span>
                    </div>
                  )}
                  {!isRSVPOnly && (
                    <input
                      type="checkbox"
                      disabled={participant.needRide === "No"}
                      className="checkbox checkbox-sm mr-4"
                      checked={selectedParticipants.includes(participant)}
                      onChange={(e) =>
                        handleCheckboxChange(participant, e.target.checked)
                      }
                    />
                  )}
                  <div className="flex-grow">
                    <div className="flex items-center">
                      <a
                        href={`/p/${participant.uniqueCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {participant.name}{" "}
                        {participant.noOfPpl > 1 && (
                          <span className="ml-2 bg-[#004a7e] text-white py-1 px-2  text-xs">
                            {participant.noOfPpl}{" "}
                            <FontAwesomeIcon icon={faUsers} />
                          </span>
                        )}
                      </a>
                    </div>

                    {!isRSVPOnly && (
                      <div className="text-sm text-gray-500">
                        {participant.rideLocation}
                      </div>
                    )}
                    <div className="text-sm  text-blue-500">
                      {participant.messageForAdmin}
                    </div>
                  </div>
                  {!isRSVPOnly && (
                    <div>
                      {participant.assignedDriverId ? (
                        <div className="driver-ribbon">
                          <span>
                            <i className="fas fa-car-side"></i> &nbsp;
                            {participant.assignedDriverId.name}
                          </span>
                        </div>
                      ) : (
                        participant.needRide === "Yes" && (
                          <div className="driver-ribbon-no">
                            <span>&nbsp; No Driver</span>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p>No participants to display.</p>
        )}
      </div>
    </div>
  );
};

export default ParticipantsList;
