const RsvpSubmitted = ({ message }) => {
  return (
    <div
      className="flex items-center justify-center min-h-screen p-4"
      style={{
        backgroundImage: "url('/background.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-lg bg-white shadow-lg rounded-lg p-6 border border-gray-200">
        <div className="flex items-center space-x-3">
          <svg
            className="h-8 w-8 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <h2 className="text-lg font-semibold text-gray-900">
            RSVP Confirmed!
          </h2>
        </div>

        <div className="mt-3 text-gray-700 leading-relaxed">
          {typeof message === "string" ? <span>{message}</span> : message}
        </div>
      </div>
    </div>
  );
};

export default RsvpSubmitted;
