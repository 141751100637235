import React, { useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";

const EventActionHistory = ({ eventId }) => {
  const { data: actions, fetchItems: fetchActionHistory } = useCRUD(
    `/api/action-history/event/${eventId}`
  );

  useEffect(() => {
    fetchActionHistory();
  }, [fetchActionHistory]);

  const interpretAction = (action) => {
    const { participantId, actionType, details: details1, timestamp } = action;
    const date = new Date(timestamp).toLocaleString();

    let details = {};

    // Check if details is a valid JSON string before parsing
    if (details1) {
      try {
        details = JSON.parse(details1);
      } catch (e) {
        console.error("Error parsing details:", e);
        // Handle parsing error (e.g., set parsedDetails to an empty object or log the error)
        details = {}; // Assuming details is an associative array, keep it as an object
      }
    }
    // Extract eventType dynamically from action's eventId object
    const eventType = action.eventId?.eventType || "";
    switch (actionType) {
      case "UpdateAvailability":
        if (participantId.isDriver) {
          return details.availability === "Yes"
            ? `Driver ${participantId.name} indicated they can help with pickups.`
            : `Driver ${participantId.name} indicated they cannot help with pickups.`;
        } else {
          // Check if the event is RSVP Only and update the message accordingly
          if (eventType === "RSVP Only") {
            return details.needRide === "Yes"
              ? `${participantId.name} indicated they will attend the event.`
              : `${participantId.name} indicated they won't be attending.`;
          } else {
            return details.needRide === "Yes"
              ? `${participantId.name} indicated they need a ride.`
              : `${participantId.name} indicated they don't need a ride.`;
          }
        }
      case "MessageForAdmin":
        return `${participantId.name} sent a message to admin: ${details.messageForAdmin}.`;
      case "UpdateInfo":
        if (details.canTake !== undefined) {
          return `${participantId.name} updated that they can take ${details.canTake} people.`;
        } else if (details.rideLocation !== undefined) {
          return `${participantId.name} updated their ride location to ${details.rideLocation}.`;
        }
        break;
      case "AssignmentConfirmation":
        return `Driver ${participantId.name} confirmed their pickup list.`;
      default:
        return `Action performed by ${participantId.name}.`;
    }
  };

  // Sort actions by timestamp in descending order
  const sortedActions = actions?.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  return (
    <div className="card bg-base-100 shadow-xl mt-3">
      <div className="card-body">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Action History</h3>
        </div>
        <div
          className="overflow-x-auto"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          <table className="min-w-full leading-normal">
            <thead>
              <tr>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedActions?.map((action) => (
                <tr key={action.id}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {new Date(action.timestamp).toLocaleString()}
                    </p>
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {interpretAction(action)}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EventActionHistory;
