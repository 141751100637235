import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useCRUD from "../hooks/useCRUD";
import { formatDate } from "../utils/dateUtils";
import RsvpSubmitted from "../components/Event/RsvpSubmitted";
import Spinner from "../components/Spinner/Spinner";

const ParticipantRsvp = () => {
  const { uniqueCode } = useParams();
  const {
    data: eventParticipation,
    fetchItems: fetchEventParticipation,
    isLoading,
    error,
  } = useCRUD(`/api/event-participations/rsvp/${uniqueCode}`);

  const { updateItem } = useCRUD(`/api/event-participations/rsvp`);
  const [rsvpStatus, setRsvpStatus] = useState({
    submitted: false,
    message: "",
  });

  const [needRide, setNeedRide] = useState("");
  const [rideLocation, setRideLocation] = useState("");
  const [initialRideLocation, setInitialRideLocation] = useState("");
  const [messageForAdmin, setMessageForAdmin] = useState("");
  const [initialMessageForAdmin, setInitialMessageForAdmin] = useState("");

  useEffect(() => {
    fetchEventParticipation();
  }, [fetchEventParticipation]);

  useEffect(() => {
    if (eventParticipation) {
      setNeedRide(eventParticipation.needRide);
      setRideLocation(eventParticipation.rideLocation || "");
      setInitialRideLocation(eventParticipation.rideLocation || "");
      setMessageForAdmin(eventParticipation.messageForAdmin || "");
      setInitialMessageForAdmin(eventParticipation.messageForAdmin || "");
    }
  }, [eventParticipation]);

  // Function to detect links and make them clickable
  const formatMessageWithLinks = (message) => {
    if (!message) return null;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.split(urlRegex).map((part, index) =>
      part.match(urlRegex) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  const handleRsvpChange = async () => {
    const updatePayload = {
      needRide: needRide === "YesDiff" ? "Yes" : needRide,
      ...(needRide === "YesDiff" &&
        rideLocation !== initialRideLocation && { rideLocation }),
      ...(messageForAdmin !== initialMessageForAdmin && { messageForAdmin }),
    };

    updateItem(uniqueCode, updatePayload)
      .then(() => {
        const successMessage =
          needRide === "Yes" &&
          eventParticipation.eventDetails.eventMessageForYesRsvp
            ? formatMessageWithLinks(
                eventParticipation.eventDetails.eventMessageForYesRsvp
              )
            : "Your RSVP has been recorded.";

        setRsvpStatus({
          submitted: true,
          message: successMessage,
        });
      })
      .catch((error) => {
        console.error("Error updating RSVP:", error);
        setRsvpStatus({
          submitted: false,
          message:
            error.message || "Failed to record your RSVP. Please try again.",
        });
      });
  };

  if (rsvpStatus.submitted) {
    return <RsvpSubmitted message={rsvpStatus.message} />;
  }

  if (isLoading) return <Spinner />;
  if (error || !eventParticipation) {
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  }

  if (eventParticipation.isDriver) {
    return (
      <div className="card bg-white shadow-lg p-4">
        <div className="text-center text-red-500 p-4">Invalid Request</div>
      </div>
    );
  }

  const { name, eventDetails } = eventParticipation;
  const isRSVPOnly = eventDetails.eventType === "RSVP Only"; // Check event type

  return (
    <div
      className="flex items-center justify-center min-h-screen"
      style={{
        backgroundImage: "url('/background.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-lg mx-auto p-4 bg-white rounded-lg shadow">
        <form onSubmit={(e) => e.preventDefault()} className="space-y-4">
          {/* Display account logo if available */}
          {eventParticipation.accountLogo && (
            <div className="text-center mb-4">
              <img
                src={eventParticipation.accountLogo}
                alt="Account Logo"
                className="mx-auto"
                style={{ maxWidth: "150px", maxHeight: "150px" }}
              />
            </div>
          )}

          <h1 className="text-xl font-semibold">Hi {name},</h1>
          <p>
            {isRSVPOnly
              ? `Will you be attending ${eventDetails.title} on `
              : `Do you need a ride for ${eventDetails.title} on `}
            <strong>{formatDate(eventDetails.dateOfOccurrence)}</strong>?
          </p>

          {/* General event message for participants */}
          {eventDetails.eventMessageForParticipants && (
            <div className="bg-blue-100 p-3 rounded-lg mb-4">
              <p>
                {formatMessageWithLinks(
                  eventDetails.eventMessageForParticipants
                )}
              </p>
            </div>
          )}

          <div className="flex flex-col gap-4">
            {isRSVPOnly ? (
              <>
                <div
                  className={`p-4 rounded-lg cursor-pointer text-center 
                  ${
                    needRide === "Yes"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setNeedRide("Yes")}
                >
                  Yes, I will be there
                </div>

                <div
                  className={`p-4 rounded-lg cursor-pointer text-center 
                  ${
                    needRide === "No" ? "bg-red-500 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => setNeedRide("No")}
                >
                  No, I won’t be able to make it
                </div>
              </>
            ) : (
              <>
                <div
                  className={`p-4 rounded-lg cursor-pointer text-center 
                  ${
                    needRide === "Yes"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => {
                    setNeedRide("Yes");
                    setRideLocation(eventParticipation.rideLocation);
                  }}
                >
                  Yes, need pickup from {eventParticipation.rideLocation}
                </div>

                <div
                  className={`p-4 rounded-lg cursor-pointer text-center 
                  ${
                    needRide === "YesDiff"
                      ? "bg-green-500 text-white"
                      : "bg-gray-200"
                  }`}
                  onClick={() => setNeedRide("YesDiff")}
                >
                  Yes, need pickup from a different location
                </div>

                {needRide === "YesDiff" && (
                  <div>
                    <label className="label"> New Ride Location: </label>
                    <input
                      type="text"
                      name="rideLocation"
                      value={rideLocation}
                      onChange={(e) => setRideLocation(e.target.value)}
                      className="input input-bordered w-full"
                      placeholder="Enter new ride location"
                    />
                  </div>
                )}

                <div
                  className={`p-4 rounded-lg cursor-pointer text-center ${
                    needRide === "No" ? "bg-red-500 text-white" : "bg-gray-200"
                  }`}
                  onClick={() => setNeedRide("No")}
                >
                  No, I don’t need a ride
                </div>
              </>
            )}
          </div>

          {/* Message for admin */}
          <div>
            <input
              type="text"
              name="messageForAdmin"
              value={messageForAdmin}
              onChange={(e) => setMessageForAdmin(e.target.value)}
              className="input input-bordered w-full"
              placeholder="Enter message for admin if needed"
            />
          </div>

          <button className="btn btn-primary w-full" onClick={handleRsvpChange}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ParticipantRsvp;
