import React, { useState, useEffect } from "react";
import EventDetails from "./EventDetails";
import StatisticsTiles from "./StatisticsTiles";
import ParticipantsList from "./ParticipantsList";
import DriversList from "./DriversList.js";
import RidesList from "./RidesList";
import useCRUD from "../../hooks/useCRUD";
import { useParams, useHistory } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { formatDateWithDay } from "../../utils/dateUtils.js";
import EventActionHistory from "./EventActionHistory.js";

const ManageEventDashboard = () => {
  const { id } = useParams();

  const [eventDetails, setEventDetails] = useState(null);
  const { data: eventParticipationsData, fetchData: fetchEventParticipations } =
    useCRUD(`/api/event-participations/event/${id}`);

  const { fetchData: fetchEventParticipationsConfig } = useCRUD(
    `/api/events/${id}/create-participation`
  );

  const { data: suggestionsData, fetchData: fetchSuggestions } = useCRUD(
    `/api/event-participations/auto-suggest/${id}`
  );

  const [eventParticipations, setEventParticipations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [configFetchAttempted, setConfigFetchAttempted] = useState(false);

  const [isEventExpired, setIsEventExpired] = useState(false);

  const history = useHistory();

  // Check if event is RSVP Only
  const isRSVPOnly = eventDetails?.eventSignUpType === "RSVP Only";

  const refreshEventParticipations = async () => {
    try {
      setIsLoading(true);
      console.log("Fetching event participations...");
      const fetchedEventParticipations = await fetchEventParticipations();

      console.log("Fetched event participations:", fetchedEventParticipations);
      if (fetchedEventParticipations) {
        // Make sure fetchedEventParticipations is not null

        console.log("Event participations:", fetchedEventParticipations);

        setEventDetails(fetchedEventParticipations);
        setEventParticipations(
          fetchedEventParticipations?.eventParticipations || []
        );
      }
    } catch (error) {
      console.error("Error fetching event participations:", error);
    } finally {
      setIsLoading(false);
      //Reload the page
    }
  };

  const handleSendToGoup = () => {
    // Navigate to the manage event page
    history.push(`/group/${eventDetails?.participantGroup}`);
  };

  const manageConfigEventParticipations = async () => {
    if (!configFetchAttempted) {
      console.log("Fetching event participations config...");
      await fetchEventParticipationsConfig(); // Make sure this function awaits the fetch
      setConfigFetchAttempted(true); // Mark that the fetch has been attempted
      refreshEventParticipations(); // Optionally refresh participations after config fetch
    }
  };
  useEffect(() => {
    refreshEventParticipations();
    fetchSuggestions();
  }, [fetchEventParticipations]);

  useEffect(() => {
    if (eventDetails?.pingSchedule === null && !configFetchAttempted) {
      console.log("Configuring event participations...");
      manageConfigEventParticipations();
    }
  }, [eventDetails, configFetchAttempted]); // Depend on both eventDetails and configFetchAttempted

  // New useEffect for the additional trigger
  useEffect(() => {
    console.log("Checking if event has expired...");
    const initiateManageConfig = async () => {
      const now = new Date(); // This is in local time
      const nowUtc = new Date(now.toISOString()); // Convert now to UTC

      const pingScheduleDate = new Date(eventDetails?.pingSchedule + "Z"); // Appending 'Z' to indicate UTC
      const dateOfOccurrence = new Date(eventDetails?.dateOfOccurrence + "Z"); // Appending 'Z' to indicate UTC

      const dayAfterOccurrence = new Date(dateOfOccurrence);
      dayAfterOccurrence.setUTCDate(dayAfterOccurrence.getUTCDate() + 1); // Increment the day in UTC

      // Check if the current date is more than 24 hours after the date of occurrence
      const isEventExpired = nowUtc.getTime() > dayAfterOccurrence.getTime();

      if (isEventExpired) {
        console.log("Event has expired. Skipping configuration.");
        setIsEventExpired(true);
        return; // Exit the function if the event has expired
      }

      if (
        nowUtc > pingScheduleDate &&
        nowUtc < dayAfterOccurrence &&
        eventParticipations.length <
          eventDetails?.participantGroupDetails?.noOfParticipants
      ) {
        const isConfirmed = window.confirm(
          "You have added additional participants. Do you want to send RSVPs to them?"
        );
        if (isConfirmed) {
          await manageConfigEventParticipations();
        }
      }
    };

    if (eventDetails) {
      initiateManageConfig();
    }
  }, [eventDetails]); // Add dependencies as needed

  return (
    <div className="container mx-auto p-4">
      {isLoading && <Spinner />}

      <EventDetails
        event={eventDetails}
        isEventExpired={isEventExpired}
        refreshData={refreshEventParticipations}
      />
      {!isLoading &&
        eventDetails?.participantGroupDetails?.noOfParticipants < 1 && (
          <div className="alert alert-warning mt-5">
            This event doesn't have any participants or drivers. Add members by
            clicking the button.{" "}
            <button
              className="btn btn-primary btn-sm"
              onClick={handleSendToGoup}
            >
              Manage Group Members
            </button>
          </div>
        )}

      {!isLoading &&
        eventParticipations.length === 0 &&
        (eventDetails?.pingParticipants === true ||
          eventDetails?.pingDrivers === true) && (
          <div className="alert alert-warning mt-5">
            This event is configured to ping participants or drivers for
            availability.<br></br> You can configure pickups after notifications
            go out on {formatDateWithDay(eventDetails?.pingSchedule)}.
            {/* To
            trigger notifications manually, click{" "}
            <button
              className="btn btn-primary btn-sm"
              onClick={manageConfigEventParticipations}
            >
              Send Reminders Now
            </button> */}
          </div>
        )}
      {eventParticipations.length > 0 && (
        <div>
          <StatisticsTiles
            eventId={id}
            eventParticipations={eventParticipations}
            isRSVPOnly={eventDetails?.eventSignUpType === "RSVP Only"}
          />

          {/* Hide RidesList if RSVP Only */}
          {!isRSVPOnly && !isEventExpired && (
            <RidesList
              eventId={id}
              eventParticipations={eventParticipations}
              refreshData={refreshEventParticipations}
              event={eventDetails}
              isEventExpired={isEventExpired}
            />
          )}

          <ParticipantsList
            eventId={id}
            eventParticipations={eventParticipations}
            refreshData={refreshEventParticipations}
            event={eventDetails}
            isEventExpired={isEventExpired}
            suggestions={suggestionsData}
          />
          {/* Hide DriversList if RSVP Only */}
          {!isRSVPOnly && (
            <DriversList
              eventId={id}
              eventParticipations={eventParticipations}
              refreshData={refreshEventParticipations}
              event={eventDetails}
              isEventExpired={isEventExpired}
            />
          )}
          <EventActionHistory eventId={id} />
        </div>
      )}
    </div>
  );
};

export default ManageEventDashboard;
