import React, { useEffect, useState } from "react";

const StatisticsTiles = ({ eventId, eventParticipations, isRSVPOnly }) => {
  const [stats, setStats] = useState({
    numberOfMembers: 0,
    numberOfRideRequests: 0,
    numberOfDrivers: 0,
    numberOfRidesAssigned: 0,
  });

  useEffect(() => {
    if (eventParticipations) {
      const numberOfMembers = eventParticipations.length;
      const numberOfRideRequests = eventParticipations.reduce(
        (acc, participation) =>
          participation.needRide === "Yes" && !participation.isDriver
            ? acc + (participation.noOfPpl || 1)
            : acc,
        0
      );
      const numberOfDrivers = eventParticipations.filter(
        (participation) =>
          participation.isDriver && participation.availability === "Yes"
      ).length;
      const numberOfRidesAssigned = eventParticipations.reduce(
        (acc, participation) =>
          participation.assignedDriverId
            ? acc + (participation.noOfPpl || 1)
            : acc,
        0
      );

      setStats({
        numberOfMembers,
        numberOfRideRequests,
        numberOfDrivers,
        numberOfRidesAssigned,
      });
    }
  }, [eventParticipations]);

  return (
    <div className="mt-4">
      <div
        className={`grid ${
          isRSVPOnly
            ? "grid-cols-1"
            : "grid-cols-3 sm:grid-cols-2 lg:grid-cols-3"
        } gap-4 text-center`}
      >
        {/* RSVP-Only Event: Show Confirmed Participants */}
        {isRSVPOnly && (
          <div className="stats shadow">
            <div className="stat">
              <div className="stat-title">Confirmed Participants</div>
              <div className="stat-value text-green-700">
                {stats.numberOfRideRequests}
              </div>
            </div>
          </div>
        )}

        {/* Ride Coordination Event: Show Ride-Related Stats */}
        {!isRSVPOnly && (
          <>
            <div className="stats shadow">
              <div className="stat">
                <div className="stat-title hidden sm:block">
                  Participants Requesting Rides
                </div>
                <div className="stat-title block sm:hidden">Requests</div>
                <div className="stat-value text-blue-600">
                  {stats.numberOfRideRequests}
                </div>
              </div>
            </div>

            <div className="stats shadow">
              <div className="stat">
                <div className="stat-title hidden sm:block">
                  Number of Drivers Available
                </div>
                <div className="stat-title block sm:hidden">Drivers</div>
                <div className="stat-value text-yellow-400">
                  {stats.numberOfDrivers}
                </div>
              </div>
            </div>

            <div className="stats shadow">
              <div className="stat">
                <div className="stat-title hidden sm:block">Rides Assigned</div>
                <div className="stat-title block sm:hidden">Assigned</div>
                <div className="stat-value text-green-700">
                  {stats.numberOfRidesAssigned}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StatisticsTiles;
