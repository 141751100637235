import React, { useState } from "react";
import InputMask from "react-input-mask";
import debounce from "lodash/debounce";
import useCRUD from "../../hooks/useCRUD";

const AddOrEditParticipantModal = ({
  isOpen,
  onClose,
  isDriver,
  initialData,
  onSave,
  event,
  isRSVPOnly,
}) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || "",
    firstName: initialData?.firstName || "",
    lastName: initialData?.lastName || "",
    phone: initialData?.phone || "",
    noOfPpl: initialData?.noOfPpl || 1,
    rideLocation:
      isDriver || isRSVPOnly ? undefined : initialData?.rideLocation || "",
    canTake: isDriver ? initialData?.canTake || 0 : undefined,
    isDriver: isDriver,
    participantGroupId:
      initialData?.participantGroupId || event?.participantGroup,
    pauseNotifications: initialData?.pauseNotifications || false,
  });

  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const { addItem } = useCRUD("/api/participants/suggestions");

  // Debounce API call for name suggestions
  const debouncedFetchSuggestions = debounce((searchTerm) => {
    if (searchTerm.trim().length >= 3) {
      fetchSuggestions(searchTerm);
    } else {
      setSuggestions([]);
    }
  }, 300);

  const fetchSuggestions = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSuggestions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await addItem({ searchTerm });
      const data = response.data;

      setSuggestions(
        data.map((s) => ({
          name: s.name,
          phone: s.phone,
          firstName: s.firstName,
          lastName: s.lastName,
          canTake: s.canTake,
          rideLocation: s.rideLocation,
          noOfPpl: s.noOfPpl,
        }))
      );
      setShowSuggestions(true);
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const previousValueLength = formData[name]?.length || 0;

    setFormData((prev) => ({ ...prev, [name]: value }));

    if (
      !initialData &&
      name === "firstName" &&
      value.length >= 3 &&
      value.length > previousValueLength
    ) {
      debouncedFetchSuggestions(value);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setFormData((prev) => ({
      ...prev,
      firstName: suggestion.firstName || "",
      lastName: suggestion.lastName || "",
      phone: suggestion.phone || "",
      canTake: suggestion.canTake || "",
      rideLocation: suggestion.rideLocation || "",
    }));
    setShowSuggestions(false);
  };

  const phoneNumberMask =
    formData.phone && formData.phone.length > 10
      ? "1 (999) 999-9999"
      : "(999) 999-9999";

  const validateFormData = () => {
    if (!formData.firstName || !formData.phone) {
      setError("First Name and phone number are required");
      return false;
    }
    if (isDriver && !formData.canTake) {
      setError("Can take is required");
      return false;
    }
    if (!isDriver && !isRSVPOnly && !formData.rideLocation) {
      setError("Ride location is required");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateFormData()) return;
    onSave(formData);
    onClose();
  };

  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
      <div className="relative p-8 bg-blue-100 w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center pb-3">
          <p className="text-2xl font-bold">
            {initialData ? "Edit" : "Add New"} {isDriver ? "Driver" : "Member"}
          </p>
          <div className="modal-close cursor-pointer z-50" onClick={onClose}>
            <svg
              className="fill-current text-black"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {error && <p className="text-red-500">{error}</p>}
          <div className="mb-4 relative">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              required
              autoComplete="off"
            />

            {showSuggestions && (
              <ul className="absolute z-10 w-full list-none bg-white border mt-1 shadow-lg max-h-60 overflow-auto">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    className="p-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => handleSelectSuggestion(suggestion)}
                  >
                    {suggestion.firstName} {suggestion.lastName} -{" "}
                    {suggestion.phone}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2">
              Phone
            </label>
            <InputMask
              mask={phoneNumberMask}
              value={formData.phone}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              name="phone"
              required
              autoComplete="off"
            />
          </div>

          {!isDriver && !isRSVPOnly && (
            //Add new field for no. of people
            <>
              <div className="mb-4">
                <label className="block text-grey-darker text-sm font-bold mb-2">
                  No. of People
                </label>
                <input
                  type="number"
                  name="noOfPpl"
                  value={formData.noOfPpl}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                />
              </div>
            </>
          )}

          {!isDriver && !isRSVPOnly && (
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Ride Location
              </label>
              <input
                type="text"
                name="rideLocation"
                value={formData.rideLocation}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                autoComplete="off"
              />
            </div>
          )}

          {isDriver && !isRSVPOnly && (
            <div className="mb-4">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Can Take (No. of people)
              </label>
              <input
                type="number"
                name="canTake"
                value={formData.canTake}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              />
            </div>
          )}

          {initialData && (
            <div className="form-control w-full max-w-xs">
              <label className="label cursor-pointer">
                <span className="label-text">
                  {formData?.pauseNotifications ? "Resume" : "Pause"}{" "}
                  {isDriver ? "Driver" : "Member"}
                </span>
                <input
                  type="checkbox"
                  checked={formData.pauseNotifications}
                  onChange={handleToggle}
                  name="pauseNotifications"
                  className="toggle toggle-error"
                />
              </label>
            </div>
          )}

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Save
            </button>
            <button
              className="bg-transparent hover:bg-grey text-grey-darkest font-semibold py-2 px-4 border border-grey rounded"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrEditParticipantModal;
