import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import useCRUD from "../../hooks/useCRUD";
import Participants from "./Participants";
import Drivers from "./Drivers";
import useFileUpload from "../../hooks/useFileUpload";
import UploadModal from "./UploadModal";
import Spinner from "../Spinner/Spinner";
import WithFeatureRestriction from "../WithFeatureRestriction";

const ManageGroup = () => {
  const { id } = useParams();

  const { data: groupDetails, fetchItems: getGroupDetails } = useCRUD(
    `/api/participant-groups/${id}`
  );

  const [members, setMembers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [participants, setParticipants] = useState([]);

  const { uploadFile, isLoading, error } = useFileUpload(
    "/api/participants/upload"
  );

  useEffect(() => {
    getGroupDetails();
  }, []);
  useEffect(() => {
    if (groupDetails) {
      setMembers(groupDetails.participants || []);

      const filteredParticipants =
        groupDetails.participants?.filter((member) => !member.isDriver) || [];
      setParticipants(filteredParticipants);

      const filteredDrivers =
        groupDetails.participants?.filter((member) => member.isDriver) || [];
      setDrivers(filteredDrivers);
    }
  }, [groupDetails]); // Watch for changes in groupDetails

  const handleUpload = async (file) => {
    const participantGroupId = id;
    let result;

    try {
      result = await uploadFile(file, { participantGroupId });
      // Assuming result contains the response body
      // Check if the upload was successful, based on your API's success response structure
      if (result && !result.errors) {
        console.log("File uploaded successfully:", result);
        getGroupDetails();
        alert("File uploaded successfully.");
      } else {
        // If there are errors, format them into a single string and show in alert
        const errors = result?.errors
          .map((errorArray) => errorArray.join("\n"))
          .join("\n");
        alert(`Upload error:\n${errors}`);
      }
    } catch (error) {
      // This catch block is for network errors or any issues in the try block
      console.error("Upload error:", error);
      // Assuming 'error' is an object that contains a message, adjust as needed
      alert(`Upload error: ${error.message || "An unknown error occurred"}`);
    }
  };

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  if (!groupDetails) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold">{groupDetails?.groupName}</h2>

      <div className="flex justify-end">
        <WithFeatureRestriction featureKey="upload_attendees_spreadsheet">
          {(isDisabled) => (
            <button
              className={`btn btn-primary bg-[#004a7e] text-white mt-4 `}
              onClick={() => !isDisabled && setIsUploadModalOpen(true)}
              disabled={isDisabled}
            >
              + Upload Spreadsheet
            </button>
          )}
        </WithFeatureRestriction>
      </div>

      {isUploadModalOpen && (
        <UploadModal
          isOpen={isUploadModalOpen}
          onClose={() => setIsUploadModalOpen(false)}
          onUpload={handleUpload}
          isRSVPOnly={groupDetails.eventType === "RSVP Only"}
        />
      )}
      <Participants
        participants={participants}
        refreshData={getGroupDetails}
        event={groupDetails}
        groupId={id}
        isRSVPOnly={groupDetails.eventType === "RSVP Only"}
      />

      {groupDetails.eventType !== "RSVP Only" && (
        <Drivers
          drivers={drivers}
          refreshData={getGroupDetails}
          event={groupDetails}
          groupId={id}
        />
      )}
    </div>
  );
};

export default ManageGroup;
