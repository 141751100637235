import React, { useState, useEffect } from "react";
import useCRUD from "../../hooks/useCRUD";
import TemplateField from "../Elements/TemplateField";
import { formatDateShort } from "../../utils/dateUtils";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

const SmsTemplateForm = () => {
  const { id } = useParams();

  const { data: event, fetchItems: fetchEvent } = useCRUD(`/api/events/${id}`);
  const { updateItem: updateEvent } = useCRUD(`/api/events`);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toast, setToast] = useState(false);
  const [isEventExpired, setIsEventExpired] = useState(false);
  const [updateRecurring, setUpdateRecurring] = useState(false); // New checkbox state

  useEffect(() => {
    fetchEvent();
  }, [fetchEvent, id]);

  const templateVariables = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{link}}",
  ];
  const templateVariablesForDrivers = [
    "{{name}}",
    "{{eventName}}",
    "{{eventDate}}",
    "{{driverName}}",
    "{{driverPhone}}",
  ];

  const [form, setFormData] = useState({
    participantTemplate: "",
    driverTemplate: "",
    riderAssignment: "",
    driverAssignment: "",
    pingDrivers: "",
    pingParticipants: "",
  });

  useEffect(() => {
    if (event?.dateOfOccurrence) {
      const eventDate = moment(event.dateOfOccurrence);
      const now = moment();
      setIsEventExpired(eventDate.isBefore(now));
    }
  }, [event]);

  useEffect(() => {
    if (event) {
      setFormData({
        participantTemplate: event.participantTemplate || "",
        driverTemplate: event.driverTemplate || "",
        driverAssignment: event.driverAssignment || "",
        riderAssignment: event.riderAssignment || "",
        pingDrivers: event.pingDrivers || false,
        pingParticipants: event.pingParticipants || false,
      });
    }
  }, [event]);

  const showSuccessToast = () => {
    setToast(true);
    setTimeout(() => {
      setToast(false);
      history.push(`/event/manage/${id}`);
    }, 3000);
  };

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setFormData({ ...form, [e.target.name]: value });
  };

  const validateForm = () => {
    if (
      form.pingParticipants &&
      !form.participantTemplate.includes("{{link}}")
    ) {
      setError("Participant RSVP SMS Template must include {{link}}.");
      return false;
    }
    if (
      !isRSVPOnly &&
      form.pingDrivers &&
      !form.driverTemplate.includes("{{link}}")
    ) {
      setError("Driver RSVP SMS Template must include {{link}}.");
      return false;
    }
    if (
      !isRSVPOnly &&
      form.riderAssignment &&
      !form.riderAssignment.includes("{{driverName}}")
    ) {
      setError("Rider Assignment Template must include {{driverName}}.");
      return false;
    }
    if (
      !isRSVPOnly &&
      form.driverAssignment &&
      !form.driverAssignment.includes("{{link}}")
    ) {
      setError("Driver Assignment Template must include {{link}}.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    setError("");

    try {
      await updateEvent(id, { ...form, updateRecurring });
      showSuccessToast();
    } catch (error) {
      setError("Something went wrong. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const isRSVPOnly = event?.eventSignUpType === "RSVP Only";
  const showConfiguration = !(isRSVPOnly && !form.pingParticipants);

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Configure SMS Templates for {event?.title} -{" "}
          {formatDateShort(event?.dateOfOccurrence)}
        </h2>
        {showConfiguration && (
          <button
            onClick={() => history.push(`/event/manage/${id}`)}
            className="bg-primary hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
          >
            Manage Event
          </button>
        )}
      </div>

      {toast && (
        <div className="fixed top-20 right-20 bg-green-500 text-white p-3 rounded">
          Changes saved successfully!
        </div>
      )}

      {!showConfiguration ? (
        <div className="bg-yellow-100 p-4 rounded-lg text-center">
          <p className="text-lg font-semibold text-gray-700">
            This is an RSVP-only event, and no participant notifications are
            enabled. There’s nothing to configure.
          </p>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-6 bg-white shadow-sm rounded-md p-6"
        >
          <div className="border-t border-gray-200 pt-4">
            <fieldset>
              <legend className="text-lg font-medium text-gray-900">
                SMS Template Configuration
              </legend>
              <p className="text-sm my-4 p-2 rounded">
                Add dynamic variables by selecting them and clicking Insert. If
                no SMS template is set, the default template will be used.
              </p>

              <div className="mt-4 space-y-4">
                {form.pingParticipants && (
                  <div>
                    <TemplateField
                      id="participantTemplate"
                      name="participantTemplate"
                      label="Participant RSVP SMS Template"
                      tooltip="Message sent to participants for RSVP confirmation. Must include {{link}}."
                      placeholder="Hi {{name}}, RSVP for {{eventName}} on {{eventDate}} here: {{link}} (no-reply)"
                      templateVariables={templateVariables}
                      value={form.participantTemplate}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {!isRSVPOnly && (
                  <>
                    {form.pingDrivers && (
                      <div>
                        <TemplateField
                          id="driverTemplate"
                          name="driverTemplate"
                          label="Driver RSVP SMS Template"
                          tooltip="Message sent to drivers to confirm availability. Must include {{link}}."
                          placeholder="Hi {{name}}, can you provide rides for {{eventName}} on {{eventDate}}? Reply here: {{link}} (no-reply)"
                          templateVariables={templateVariables}
                          value={form.driverTemplate}
                          onChange={handleChange}
                        />
                      </div>
                    )}

                    <div>
                      <TemplateField
                        id="riderAssignment"
                        name="riderAssignment"
                        label="Rider Assignment SMS Template"
                        tooltip="Message sent to participants informing them of their assigned driver. Must include {{driverName}}."
                        placeholder="Hi {{name}}, {{driverName}} will pick you up for {{eventName}} on {{eventDate}}."
                        templateVariables={templateVariablesForDrivers}
                        value={form.riderAssignment}
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <TemplateField
                        id="driverAssignment"
                        name="driverAssignment"
                        label="Driver Assignment SMS Template"
                        tooltip="Message sent to drivers with their pickup list. Must include {{link}}."
                        placeholder="Hi {{name}}, here is your pickup list for {{eventName}} on {{eventDate}}. Click here: {{link}} (no-reply)"
                        templateVariables={templateVariables}
                        value={form.driverAssignment}
                        onChange={handleChange}
                      />
                    </div>
                  </>
                )}
              </div>
            </fieldset>
          </div>

          {/* New checkbox for updating recurring events */}
          <div className="mt-4">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={updateRecurring}
                onChange={(e) => setUpdateRecurring(e.target.checked)}
              />
              <span className="ml-2">
                Update Templates for All Recurring Events for this Event
              </span>
            </label>
          </div>

          {error && <div className="text-red-500 font-bold">{error}</div>}

          {!isEventExpired && (
            <div className="pt-5">
              <button
                type="submit"
                disabled={isLoading}
                className="btn btn-primary w-full"
              >
                {isLoading ? "Saving..." : "Save SMS Templates"}
              </button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default SmsTemplateForm;
