import React, { useState } from "react";
import DownloadCsvTemplate from "./DownloadCsvTemplate";

const UploadModal = ({ isOpen, onClose, onUpload, isRSVPOnly }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    if (!selectedFile.name.endsWith(".csv")) {
      alert("Please upload a CSV file.");
      return;
    }

    if (selectedFile.size > 5 * 1024 * 1024) {
      // 5MB
      alert("File size should not exceed 5MB.");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const requiredHeaders = isRSVPOnly
        ? ["firstName", "lastName", "phone", "noOfPpl"]
        : [
            "firstName",
            "lastName",
            "phone",
            "rideLocation",
            "isDriver",
            "canTake",
            "noOfPpl",
          ];

      if (!text.startsWith(requiredHeaders.join(","))) {
        alert("CSV file does not have the correct headers.");
        return;
      }
      onUpload(selectedFile);
      onClose();
    };
    reader.readAsText(selectedFile);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full z-50">
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative mx-auto p-6 w-full max-w-lg shadow-lg rounded-md bg-white">
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-lg font-semibold text-gray-900">
              Upload Spreadsheet
            </h4>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <p className="text-sm text-gray-500 mb-4">
            Download the CSV template and upload it here. Do not change the
            column names. If a person is a driver, set isDriver to TRUE.
          </p>

          <DownloadCsvTemplate isRSVPOnly={isRSVPOnly} />
          <br />
          <br />
          <input
            type="file"
            onChange={handleFileChange}
            accept=".csv"
            className="file-input mb-4"
            required
          />
          <div className="flex justify-end">
            <button
              onClick={handleUpload}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
